import Vue from 'vue'
import Router from 'vue-router'
import store from "./store";                        //store作用暂时不清晰
import cloud from "./cloud/interface";              //定义了所有的接口
Vue.use(Router);

//不需要用户权限的路由表
const constantRoutes = [

    {
        path: '/user/avator',
        component: resolve => require(['./views/user/ResetAvator.vue'], resolve),
        meta: {title: '修改头像'}
    },
    {
        path: '/user/password',
        component: resolve => require(['./views/user/ResetPassword.vue'], resolve),
        meta: {title: '修改密码'}
    },

    //系统
    {
        path: '/system/insurance',
        component: resolve => require(['./views/system/Insurance.vue'], resolve),
        meta: {title: '保险公司'}
    }
];
//定义一个函数来创建router
const createRouter = routes => new Router({
    mode: 'history',
    base: '/cp/',
    routes: [
        {
            path: '*',
            component: () => import('./views/404.vue')
        },
        {
            path: '/',
            component: () => import('./views/Index.vue'),
            meta: {title: '主页'},
            redirect: '/home',
            children: routes
        },
        {
            path: '/login',
            component: () => import('./views/Login.vue'),
            meta: {title: '登陆'},
        },
        {
            path: '/api',
            component: () => import('./views/Api.vue'),
            meta: {title: '接口文档'},
        }
    ]
});
/**
 * 加载动态菜单和路由
 */
const loadNavRoutes = function (cb) {
    cloud.menu.getMenus().then(result => {
        if (!result.errCode) {
            let routes = addDynamicMenuRoutes(result.data);
            let children = [];
            for (let i = 0; i < routes.length; i++) {
                children.push(routes[i])
            }
            constantRoutes.forEach(function (item) {
                children.push(item)
            });
            router.matcher = createRouter(children).matcher;
            //router.addRoutes(routes)
            store.commit('setMenus', result.data);
            store.commit('menuRouteLoaded', true);
            cb && cb()
        }
    }).catch(error => {
        alert(error)
    })
};

const addDynamicMenuRoutes = function (menuList = []) {
    let list = [];
    for (let i = 0; i < menuList.length; i++) {
        if (menuList[i].subs.length >= 1) {
            menuList[i].subs.forEach(function (item) {
                let route = {
                    path: item.path,
                    component: null,
                    name: item.name,
                    meta: {
                        title: item.name,
                        isDynamic: true,
                        //isTab: true
                    }
                };
                let url = item.component;
                route['component'] = resolve => require([`@/${url}`], resolve);
                list.push(route)
            })
        }
    }
    return list
};
// 初始化路由的时候，只挂载不需要用户权限的路由表
const router = createRouter(constantRoutes);
router.beforeEach((to, from, next) => {
    if (to.path === '/login') {
        next()
    } else {
        //加载动态菜单和路由
        if (!store.state.menuRouteLoaded) {
            loadNavRoutes(() => {
                router.push({path: to.path, query: to.query})
            })
        } else {
            next()
        }
    }
});

export default router