import http from '../../cloud/http'
//系统设置
export const config = (action, data, method) => {
    data = data || {}
    method = method || 'get'
    if (method == 'get') {
        return http({
            url: '/admin/zx/config/index/' + action + '/',
            method: 'get',
            params: data
        })
    } else {
        return http({
            url: '/admin/zx/config/index/' + action + '/',
            method: 'post',
            data
        })
    }
}
//海报模板管理
export const template = (action, data, method) => {
    data = data || {}
    method = method || 'get'
    if (method == 'get') {
        return http({
            url: '/admin/zx/poster/index/' + action + '/',
            method: 'get',
            params: data
        })
    } else {
        return http({
            url: '/admin/zx/poster/index/' + action + '/',
            method: 'post',
            data
        })
    }
}
//商品
export const commodity = (action, data, method) => {
    data = data || {}
    method = method || 'get'
    if (method == 'get') {
        return http({
            url: '/admin/zx/commodity/index/' + action + '/',
            method: 'get',
            params: data
        })
    } else {
        return http({
            url: '/admin/zx/commodity/index/' + action + '/',
            method: 'post',
            data
        })
    }
}
//任务
export const task = (action, data, method) => {
    data = data || {}
    method = method || 'get'
    if (method == 'get') {
        return http({
            url: '/admin/zx/task/index/' + action + '/',
            method: 'get',
            params: data
        })
    } else {
        return http({
            url: '/admin/zx/task/index/' + action + '/',
            method: 'post',
            data
        })
    }
}
//投诉
export const report = (action, data, method) => {
    data = data || {}
    method = method || 'get'
    if (method == 'get') {
        return http({
            url: '/admin/zx/report/index/' + action + '/',
            method: 'get',
            params: data
        })
    } else {
        return http({
            url: '/admin/zx/report/index/' + action + '/',
            method: 'post',
            data
        })
    }
}

//卡券
export const coupon = (action, data, method) => {
    data = data || {}
    method = method || 'get'
    if (method == 'get') {
        return http({
            url: '/admin/zx/coupon/index/' + action + '/',
            method: 'get',
            params: data
        })
    } else {
        return http({
            url: '/admin/zx/coupon/index/' + action + '/',
            method: 'post',
            data
        })
    }
}

//悬赏
export const posts = (action, data, method) => {
    data = data || {}
    method = method || 'get'
    if (method == 'get') {
        return http({
            url: '/admin/zx/posts/index/' + action + '/',
            method: 'get',
            params: data
        })
    } else {
        return http({
            url: '/admin/zx/posts/index/' + action + '/',
            method: 'post',
            data
        })
    }
}

//商户支付的悬赏订单
export const commission = (action, data, method) => {
    data = data || {}
    method = method || 'get'
    if (method == 'get') {
        return http({
            url: '/admin/zx/commission_order/index/' + action + '/',
            method: 'get',
            params: data
        })
    } else {
        return http({
            url: '/admin/zx/commission_order/index/' + action + '/',
            method: 'post',
            data
        })
    }
}

//H5模板管理
export const display = (action, data, method) => {
    data = data || {}
    method = method || 'get'
    if (method == 'get') {
        return http({
            url: '/admin/zx/display_tpls/index/' + action + '/',
            method: 'get',
            params: data
        })
    } else {
        return http({
            url: '/admin/zx/display_tpls/index/' + action + '/',
            method: 'post',
            data
        })
    }
}

//代理商
export const broker = (action, data, method) => {
    data = data || {}
    method = method || 'get'
    if (method == 'get') {
        return http({
            url: '/admin/zx/broker/index/' + action + '/',
            method: 'get',
            params: data
        })
    } else {
        return http({
            url: '/admin/zx/broker/index/' + action + '/',
            method: 'post',
            data
        })
    }
}

//模板定制
export const custom_made = (action, data, method) => {
    data = data || {}
    method = method || 'get'
    if (method == 'get') {
        return http({
            url: '/admin/zx/custom_made_tpl/index/' + action + '/',
            method: 'get',
            params: data
        })
    } else {
        return http({
            url: '/admin/zx/custom_made_tpl/index/' + action + '/',
            method: 'post',
            data
        })
    }
}

//活动二维码管理
export const activity_qrcode = (action, data, method) => {
    data = data || {}
    method = method || 'get'
    if (method == 'get') {
        return http({
            url: '/admin/zx/qrcode/index/' + action + '/',
            method: 'get',
            params: data
        })
    } else {
        return http({
            url: '/admin/zx/qrcode/index/' + action + '/',
            method: 'post',
            data
        })
    }
}

//数据统计
export const statistics = (action, data, method) => {
    data = data || {}
    method = method || 'get'
    if (method == 'get') {
        return http({
            url: '/admin/zx/statistics/index/' + action + '/',
            method: 'get',
            params: data
        })
    } else {
        return http({
            url: '/admin/zx/statistics/index/' + action + '/',
            method: 'post',
            data
        })
    }
}

//楼栋管理
export const csBuilding = (action, data, method) => {
    data = data || {}
    method = method || 'get'
    if (method == 'get') {
        return http({
            url: '/admin/zx/cs_building/index/' + action + '/',
            method: 'get',
            params: data
        })
    } else {
        return http({
            url: '/admin/zx/cs_building/index/' + action + '/',
            method: 'post',
            data
        })
    }
}
