import http from '../../cloud/http'

//反馈管理
export const feedback = (action, data, method) => {
    data = data || {};
    method = method || 'get';
    if (method == 'get') {
        return http({
            url: '/admin/feedback/index/' + action + '/',
            method: 'get',
            params: data
        })
    } else {
        return http({
            url: '/admin/feedback/index/' + action + '/',
            method: 'post',
            data
        })
    }
};