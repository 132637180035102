import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        menus: [],
        menuRouteLoaded: false,
        dynamicMenus: [],
        editTop: 0,
        indexTop: 0,
        routePath: {
            current: null,
            previous: null
        }
    },
    mutations: {
        setMenus(state, menus) {
            state.menus = menus
        },
        menuRouteLoaded(state, menuRouteLoaded) {
            state.menuRouteLoaded = menuRouteLoaded
        },
        setEditTop(state, editTop) {
            state.editTop = editTop
        },
        setIndexTop(state, indexTop) {
            state.indexTop = indexTop
        },
        setRoutePath(state, v) {
            state.routePath = {
                current: v.current,
                previous: v.previous
            }
        },
    },
    getters: {
        //get方法
        getRoutePath: state => state.routePath
    },
    actions: {}
})
