import axios from '../../cloud/http'
//初始化
export const checkLogin = () => {
    return axios({
        url: '/admin/user/init/',
        method: 'get',
    })
};
//登陆
export const login = data => {
    return axios({
        url: '/admin/user/login/',
        method: 'post',
        data
    })
};
//切换登录
export const exchangeLogin = data => {
    return axios({
        url: '/admin/user/exchange_login/',
        method: 'post',
        data
    })
};
//切换回超管登录
export const loginBack = data => {
    return axios({
        url: '/admin/user/exchange_back/',
        method: 'post',
        data
    })
};
//退出登录
export const logout = data => {
    return axios({
        url: '/admin/user/logout/',
        method: 'get',
        data
    })
};
//保存信息
export const save = data => {
    return axios({
        url: '/admin/user/save/',
        method: 'post',
        data
    })
};
//更改密码
export const resetPassword = data => {
    return axios({
        url: '/admin/user/reset_password/',
        method: 'post',
        data
    })
};

//获取基础信息
export const personInfo = () => {
    return axios({
        url: '/admin/personnel/person_info/',
        method: 'get',
    })
};

//获取基础信息
export const savePersonInfo = data => {
    return axios({
        url: '/admin/personnel/save_person_base/',
        method: 'post',
        data
    })
};

//获取登录人员可切换的站点
export const getCanChangeSta = () => {
    return axios({
        url: '/admin/user/can_change_sta/',
        method: 'get'
    })
};

//切换站点
export const changeStation = data => {
    return axios({
        url: '/njmanage/user/change_station/',
        method: 'post',
        data
    })
};




