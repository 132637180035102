const install = Vue => {
    if (install.installed)
        return;

    install.installed = true;
    /**
     *
     * @type {{set(*=, *=): (undefined), get(*=): null, delete(*=): *, clear(): *}}
     */
    Vue.prototype.$session = {
        /**
         * 设置缓存
         * @param key
         * @param value
         */
        set(key, value) {
            // if (!key || !value) {
            //     if (!value && this.get(key)) {
            //         this.delete(key)
            //     }
            //     return
            // }
            let dataType = typeof value
            sessionStorage.setItem(key, JSON.stringify({
                type: dataType,
                data: value
            }))
        },
        /**
         * 获取缓存
         * @param key
         * @returns {null}
         */
        get(key) {
            let result = JSON.parse(sessionStorage.getItem(key))
            return result ? result.data : null
        },
        /**
         * 删除指定缓存
         * @param key
         */
        delete(key) {
            return sessionStorage.removeItem(key)
        },
        /**
         * 清除所有缓存
         */
        clear() {
            return sessionStorage.clear()
        }
    }

}

export default install