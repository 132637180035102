import http from '../../cloud/http'

export const activityList = (action, data, method) => {
    data = data || {};
    method = method || 'get';
    if (method == 'get') {
        return http({
            url: '/admin/activity/reward/index/' + action + '/',
            method: 'get',
            params: data
        })
    } else {
        return http({
            url: '/admin/activity/reward/index/' + action + '/',
            method: 'post',
            data
        })
    }
};

//保存任务（编辑）项目信息
export const taskSave = (data) => {
    return http({
        url: '/admin/activity/koi_config/index/Save',
        method: 'post',
        data
    })

}

//获取任务配置
export const getTask = (data) => {
    return http({
        url: '/admin/activity/koi_config/index/Detail',
        method: 'GET',
    })

}

//保存活动      （编辑）项目信息
export const activitySave = (data) => {
    return http({
        url: '/admin/activity/reward/index/Save',
        method: 'post',
        data
    })

}

//删除活动
export const activityDle = (action, data, method) => {
    data = data || {}
    method = method || 'get'
    if (method == 'get') {
        return http({
            url: '/admin/activity/reward/index/' + action + '/',
            method: 'get',
            params: data
        })
    } else {
        return http({
            url: '/admin/activity/reward/index/' + action + '/',
            method: 'post',
            data
        })
    }
}
//对换奖品
export const ConfirmReward = (action, data, method) => {
    data = data || {}
    method = method || 'get'
    if (method == 'get') {
        return http({
            url: '/admin/activity/reward/index/' + action + '/',
            method: 'get',
            params: data
        })
    } else {
        return http({
            url: '/admin/activity/reward/index/' + action + '/',
            method: 'post',
            data
        })
    }
}

