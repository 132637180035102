export default {
    install: function (Vue) {
        Vue.mixin({
            created: function () {
                //console.log("组件开始加载")
            },
            methods: {
                test: function () {
                    console.log("mixin test");
                }
            }
        })
        Vue.prototype.$loading.show = function (message) {
            Vue.prototype.$loading({
                lock: true,
                text: message || '加载中',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
        }
        Vue.prototype.$loading.close = function () {
            Vue.prototype.$loading().close();
        }

        Vue.prototype.$message.success = function (msg) {
            Vue.prototype.$message({
                message: msg,
                type: 'success'
            });
        }
        Vue.prototype.$message.warning = function (msg) {
            Vue.prototype.$message({
                message: msg,
                type: 'warning'
            });
        }
        Vue.prototype.$error = function (msg) {
            Vue.prototype.$message({
                message: msg,
                type: 'error',
                showClose: true,
                duration: 0
            });
        }
        Vue.prototype.$message.error = function (msg) {
            if (typeof msg == 'object') {
                msg.type = 'error'
                Vue.prototype.$message(msg);
            } else {
                Vue.prototype.$message({
                    message: msg,
                    type: 'error'
                });
            }
        }
        Vue.prototype.$message.alert = function (msg, cb) {
            if (typeof msg == 'object') {
                let title = msg.title || '提示信息'
                Vue.prototype.$alert(msg.content, title, {
                    dangerouslyUseHTMLString: true,
                    confirmButtonText: '确定',
                    callback: action => {
                        cb && cb(action)
                    }
                });
            } else {
                Vue.prototype.$alert(msg, '提示信息', {
                    dangerouslyUseHTMLString: true,
                    confirmButtonText: '确定',
                    callback: action => {
                        cb && cb(action)
                    }
                });
            }
        }
        Vue.prototype.$message.confirm = function (config, confirm, cancel) {
            Vue.prototype.$confirm(config.message || '确定要进行此操作吗', config.title || '确认信息', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                confirm && confirm()
            }).catch(() => {
                cancel && cancel()
            });
        }
    }
}