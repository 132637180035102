import axios from '../../cloud/http'

//初始化
export const getChartData = (data = {}) => {
    return axios({
        url: '/admin/statistics/chart_data/',
        method: 'post',
        data
    })
}

//累加
export const getSumData = (data = {}) => {
    return axios({
        url: '/admin/statistics/sum_data/',
        method: 'post',
        data
    })
}

//分析表格
export const chartDataList = (data = {}) => {
    return axios({
        url: '/admin/statistics/chart_data_list',
        method: 'post',
        data
    })
}
//员工排行
export const workers = (params) => {
    return axios({
        url: '/admin/user/workers/',
        method: 'get',
        params: params
    })
}