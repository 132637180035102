import axios from '../../cloud/http'


//权限管理
export const getGroups = () => {
    return axios({
        url: '/admin/system/access/',
        method: 'get',
        params: {action: 'get_groups'}
    })
}

//获取可以操作的权限节点
export const getGroupNodes = id => {
    return axios({
        url: '/admin/system/access/',
        method: 'get',
        params: {action: 'get_group_nodes', id: id}
    })
}

export const saveGroup = data => {
    data.action = 'save_group'
    return axios({
        url: '/admin/system/access/',
        method: 'post',
        data
    })
}

export const delGroup = data => {
    data.action = 'remove_group'
    return axios({
        url: '/admin/system/access/',
        method: 'post',
        data
    })
}
export const saveGroupNodes = data => {
    data.action = 'save_group_nodes'
    return axios({
        url: '/admin/system/access/',
        method: 'post',
        data
    })
}

//检测权限组是否有用户
export const checkGroupHasPerson = group_id => {
    return axios({
        url: '/admin/system/CheckGroupHasPerson',
        method: 'get',
        params: {group_id: group_id}
    })
}


export const LoadNavList = () => {
    return axios({
        url: '/admin/system/access/',
        method: 'get',
        params: {action: 'get_groups'}
    })
}
//获取用户列表
export const getUserList = (params) => {
    return axios({
        url: '/admin/user/get_list/',
        method: 'get',
        params: params
    })
}
//保存用户信息
export const saveUser = (data) => {
    return axios({
        url: '/admin/user/save/',
        method: 'post',
        data
    })
}
//权限节点操作
export const accessNodes = (data, method) => {
    method = method || 'get'

    if (method == 'get') {
        return axios({
            url: '/admin/access_nodes/index/',
            method: 'get',
            params: data
        })
    } else {
        return axios({
            url: '/admin/access_nodes/index/',
            method: 'post',
            data
        })
    }
}
//商户管理
export const merchant = (data, method) => {
    method = method || 'get'
    if (method == 'get') {
        return axios({
            url: '/admin/merchant/index/',
            method: 'get',
            params: data
        })
    } else {
        return axios({
            url: '/admin/merchant/index/',
            method: 'post',
            data
        })
    }
}
//接口管理
export const apiManage = (action, data, method) => {
    data = data || {}
    method = method || 'get'
    if (method == 'get') {
        return axios({
            url: '/admin/api/manage/?action=' + action,
            method: 'get',
            params: data
        })
    } else {
        return axios({
            url: '/admin/api/manage/?action=' + action,
            method: 'post',
            data
        })
    }
}
//接口查看
export const api = (action, data, method) => {
    data = data || {}
    method = method || 'get'
    if (method == 'get') {
        return axios({
            url: '/admin/api/index/?action=' + action,
            method: 'get',
            params: data
        })
    } else {
        return axios({
            url: '/admin/api/index/?action=' + action,
            method: 'post',
            data
        })
    }
}
//系统设置
export const config = (action, data, method) => {
    data = data || {}
    method = method || 'get'
    if (method == 'get') {
        return axios({
            url: '/admin/system/index/?action=' + action,
            method: 'get',
            params: data
        })
    } else {
        return axios({
            url: '/admin/system/index/?action=' + action,
            method: 'post',
            data
        })
    }
}
//更新文件管理
export const updater = (action, data, method) => {
    data = data || {}
    method = method || 'get'
    if (method == 'get') {
        return axios({
            url: '/admin/updater/index/?action=' + action,
            method: 'get',
            params: data
        })
    } else {
        return axios({
            url: '/admin/updater/index/?action=' + action,
            method: 'post',
            data
        })
    }
}
//任务管理
export const task = (action, data, method) => {
    data = data || {}
    method = method || 'get'
    if (method == 'get') {
        return axios({
            url: '/admin/task/index/' + action + '/',
            method: 'get',
            params: data
        })
    } else {
        return axios({
            url: '/admin/task/index/' + action + '/',
            method: 'post',
            data
        })
    }
}
//队列管理
export const queue = (action, data, method) => {
    data = data || {}
    method = method || 'get'
    if (method == 'get') {
        return axios({
            url: '/admin/queue/index/' + action + '/',
            method: 'get',
            params: data
        })
    } else {
        return axios({
            url: '/admin/queue/index/' + action + '/',
            method: 'post',
            data
        })
    }
}