import api from '../cloud/interface.js'

const User = {
    isInit: false,
    referer: '/home',
    info: {
        u_id: 0,
        u_name: ''
    },
    getName() {
        return this.info.u_name
    },
    getInfo(callback) {
        callback(this.info)
    },
    init(success, error) {
        api.user.checkLogin().then(result => {
            if (result.errCode) {
                success && success(false);
            } else {
                this.info = result.data
                this.isInit = true
                success && success(true);
            }
        }).catch(msg => {
            error && error(msg);
        }).finally(() => {

        })
    },
    reset() {
        this.info = {
            u_id: 0,
            u_name: ''
        }
    },
    logout(cb) {
        api.user.logout().then(() => {
            this.isInit = false
            this.info = {
                u_id: 0
            }
            cb && cb()
        }).catch(error => {
            console.log(error)
        })
    }
}
export default User;