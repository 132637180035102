<template>
    <div id="app">
        <router-view class="Router" v-if="isRouterAlive"/>
    </div>
</template>

<script>
    export default {
        name: 'app',
        provide() {
            return {
                reload: this.reload
            }
        },
        data() {
            return {
                isRouterAlive: true
            }
        },
        methods: {
            reload() {
                this.isRouterAlive = false;
                this.$nextTick(function () {
                    this.isRouterAlive = true
                })
            }
        },
        mounted() {
            this.$user.init();
            this.$eventHub.$on('user_changed', () => {
                //this.$user.init()
                this.$loading.show('跳转中');
                this.$router.push('/login');
                setTimeout(() => {
                    this.$loading.close();
                    window.location.reload()
                }, 1000)
            });
            // 检测浏览器路由改变页面不刷新问题,hash模式的工作原理是hashchange事件
            window.addEventListener('hashchange', () => {
                let currentPath = window.location.hash.slice(1);
                if (this.$route.path !== currentPath) {
                    this.$router.push(currentPath)
                }
            }, false)
        }
    }
</script>

<style lang="less">
    @import "assets/css/main.css";
    @import "assets/css/base.css";
    @import "assets/css/style.less";


    #app {
        font-family: 'Avenir', Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: #2c3e50;
    }

    .Router {
        height: 100%;
    }
</style>
