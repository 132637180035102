import md5 from 'js-md5';

export default {
    install: function (Vue) {
        Vue.mixin({
            created: function () {
                //console.log("组件开始加载")
            },
            methods: {
                test: function () {
                    //console.log("mixin test");
                }
            }
        })

        /**
         * md5加密
         * @type {exports}
         */
        Vue.prototype.$md5 = md5;
        /**
         * 深拷贝
         * @param origin
         * @returns {any}
         */
        Vue.prototype.$copy = function (origin) {
            return JSON.parse(JSON.stringify(origin));
        };
    }
}