import * as user from '../cloud/apis/user'
import * as menu from '../cloud/apis/menu'
import * as system from '../cloud/apis/system'
import * as statistics from '../cloud/apis/statistics'
import * as article from '../cloud/apis/article'
import * as zx from '../cloud/apis/zx'
import * as feedback from '../cloud/apis/feedback'
import * as journal from '../cloud/apis/journal'
import * as website from '../cloud/apis/website'
import * as luckDraw from '../cloud/apis/luckDraw'

export default {
    user, menu, system, statistics, article, zx, feedback, journal,website,luckDraw
}