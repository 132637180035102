import Vue from 'vue'
import App from './App.vue'
import 'element-ui/lib/theme-chalk/index.css';
import router from './router'
import store from './store'
import './plugins/element.js'
import ui from './plugins/ui/response.js'
import api from './cloud/plugin.js'
import user from './plugins/user/user.js';
import functions from './plugins/functions.js';
import session from './plugins/session.js';
import moment from 'moment';
import dateUtil from './utils/date';

import './assets/css/iconfont.css';
// import echarts from 'echarts';
// Vue.prototype.$echarts = echarts

Vue.use(ui);
Vue.use(api);
Vue.use(user);
Vue.use(functions);
Vue.use(session);
Vue.config.productionTip = false;

new Vue({
    router,
    store,
    render: h => h(App),
    data: {
        eventHub: new Vue()
    }
}).$mount('#app')
//获取日期(某个日期前后日期，例：getDatatime(-1,'2019-01-01'); 2019-01-01前一天)
Vue.prototype.getDateTime = function (day, date) {
    let dd;
    date ? dd = new Date(date) : dd = new Date();
    dd.setDate(dd.getDate() + day);
    let y = dd.getFullYear();
    let m = dd.getMonth() + 1 < 10 ? "0" + (dd.getMonth() + 1) : dd.getMonth() + 1;
    let d = dd.getDate() < 10 ? "0" + dd.getDate() : dd.getDate();
    return y + "-" + m + "-" + d;
}
// 将时间戳转日期格式的过滤器
Vue.filter('time', function (value, formatString) {  //挂载全局 时间格式化 过滤器  value  是输入内容
    if (!value || value == "0") return '--'
    formatString = formatString || 'YYYY-MM-DD HH:mm:ss';
    return moment.unix(value).format(formatString); // 这是时间戳转时间
})
//时间转换过滤器 value|dateFormat('yyyy-MM-dd')
Vue.filter('dateFormat', function (value, fmt) {
    let getDate = new Date(value);
    let o = {
        'M+': getDate.getMonth() + 1,
        'd+': getDate.getDate(),
        'h+': getDate.getHours(),
        'm+': getDate.getMinutes(),
        's+': getDate.getSeconds(),
        'q+': Math.floor((getDate.getMonth() + 3) / 3),
        'S': getDate.getMilliseconds()
    };
    if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (getDate.getFullYear() + '').substr(4 - RegExp.$1.length))
    }
    for (let k in o) {
        if (new RegExp('(' + k + ')').test(fmt)) {
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)))
        }
    }
    return fmt;
});
//秒转小时显示
Vue.filter('secondToHour', value => {
    return dateUtil.secondToHour(value)
});
//人性化时间
Vue.filter('HumanizedTime', dateTimeStamp => {
    let minute = 1000 * 60
    let hour = minute * 60
    let day = hour * 24
    let month = day * 30
    if (!dateTimeStamp || dateTimeStamp == 0) {
        return '--'
    }
    let diffValue = new Date().getTime() - dateTimeStamp * 1000;
    if (diffValue < 0) {
        //若日期不符则计算还要多久到达
        return dateUtil.secondToHour((dateTimeStamp * 1000 - new Date().getTime()) / 1000) + '后'
    }
    let monthC = diffValue / month;
    let hourC = diffValue / hour;
    let minC = diffValue / minute;
    //计算今天已经过去多少小时
    let rtime = new Date(dateTimeStamp * 1000);
    let now = new Date();
    let pastHour = parseInt(hourC);
    let hourPastToday = now.format('h');
    let hourPastWithoutToday = parseInt(hourC) - parseInt(hourPastToday);
    let result = ""

    if (pastHour <= hourPastToday) {
        if (minC < 1) {
            result = "刚刚";
        } else if (pastHour < 1) {
            result = parseInt(minC) + "分钟前";
        } else if (pastHour >= 1 && pastHour < 3) {
            result = pastHour + "小时前";
        } else {
            result = "今天" + rtime.format('hh:mm');
        }
    } else if (pastHour >= hourPastToday && hourPastWithoutToday < 24) {
        result = "昨天" + rtime.format('hh:mm');
    } else if (hourPastWithoutToday > 24 && hourPastWithoutToday <= 48) {
        result = "前天" + rtime.format('hh:mm');
    } else {
        if (monthC >= 2) {
            result = moment(rtime).format('YYYY-MM-DD hh:mm');
        } else {
            result = rtime.format('MM-dd hh:mm');
        }
    }
    return result;
})
//小数点后几位 value|number(2)
Vue.filter('number', function (value, num) {
    let result = value;
    return Number(result).toFixed(num);
});
//脱敏
Vue.filter('desensitization', function (str, headLen, tailLen, replace) {
    headLen = parseInt(headLen) || 3;
    tailLen = parseInt(tailLen) || 4;
    replace = replace || '****'
    if (!str || str == '' || str == 0 || str.length < (headLen + tailLen)) {
        return str;
    }
    return str.substr(0, headLen) + replace + str.substr(-tailLen);
})
//根据身份证号码计算年龄
Vue.filter('age', function (number) {
    if (!number) {
        return ''
    }
    let year = number.substr(6, 4)
    let month = number.substr(10, 2)
    let day = number.substr(12, 2)

    let str = year + '-' + month + '-' + day

    let r = str.match(/^(\d{1,4})(-|\/)(\d{1,2})\2(\d{1,2})/);
    if (r == null) return false;

    let d = new Date(r[1], r[3] - 1, r[4]);
    let returnStr = "输入的日期格式错误！";

    if (d.getFullYear() == r[1] && (d.getMonth() + 1) == r[3] && d.getDate() == r[4]) {

        let date = new Date();
        let yearNow = date.getFullYear();
        let monthNow = date.getMonth() + 1;
        let dayNow = date.getDate();

        let largeMonths = [1, 3, 5, 7, 8, 10, 12], //大月， 用于计算天，只在年月都为零时，天数有效
            lastMonth = monthNow - 1 > 0 ? monthNow - 1 : 12,  // 上一个月的月份
            isLeapYear = false, // 是否是闰年
            daysOFMonth = 0;    // 当前日期的上一个月多少天

        if ((yearNow % 4 === 0 && yearNow % 100 !== 0) || yearNow % 400 === 0) {  // 是否闰年， 用于计算天，只在年月都为零时，天数有效
            isLeapYear = true;
        }

        if (largeMonths.indexOf(lastMonth) > -1) {
            daysOFMonth = 31;
        } else if (lastMonth === 2) {
            if (isLeapYear) {
                daysOFMonth = 29;
            } else {
                daysOFMonth = 28;
            }
        } else {
            daysOFMonth = 30;
        }

        let Y = yearNow - parseInt(r[1]);
        let M = monthNow - parseInt(r[3]);
        let D = dayNow - parseInt(r[4]);
        if (D < 0) {
            D = D + daysOFMonth; //借一个月
            M--;
        }
        if (M < 0) {  // 借一年 12个月
            Y--;
            M = M + 12; //
        }

        if (Y < 0) {
            returnStr = "出生日期有误！";

        } else if (Y === 0) {
            if (M === 0) {
                returnStr = D + "天";
            } else {
                returnStr = M + "月";
            }
        } else {
            if (M === 0) {
                returnStr = Y + "岁";
            } else {
                returnStr = Y + "岁" + M + "月";
            }
        }

    }
    let sex = number.substr(16, 1) % 2 == 0 ? "女" : "男"
    return returnStr + " " + sex;
})
//限制字符串长度
Vue.filter('ellipsis', function (value, length) {
    if (!value) {
        return ''
    }
    if (value.length > length) {
        return value.slice(0, length) + '...'
    }
    return value
})

Vue.directive('title', {
    inserted: function (el) {
        document.title = el.dataset.title
    }
})
//微信对话窗
Vue.directive('wxchat', {
    bind: (el, binding) => {
        el.addEventListener('click', () => {
            Vue.prototype.$eventHub.$emit("_OPEN_WX_CHAT_", binding.value)
        })
    }
})
Vue.component('css', {
    render(createElement) {
        return createElement('link', {attrs: {rel: 'stylesheet', href: this.href}});
    },
    props: {
        href: {type: String, required: true},
    },
})
Vue.component('js', {
    render(createElement) {
        return createElement('script', {attrs: {type: 'text/javascript', src: this.src}});
    },
    props: {
        src: {type: String, required: true},
    },
})


//设置cookie
Vue.prototype.setCookie = function (name, value, day) {
    if (day !== 0) {
        var expires = day * 24 * 60 * 60 * 1000;
        var date = new Date(+new Date() + expires);
        document.cookie = name + "=" + escape(JSON.stringify(value)) + ";expires=" + date.toUTCString();
    } else {
        document.cookie = name + "=" + + escape(JSON.stringify(value));
    }
};

//获取cookie
Vue.prototype.getCookie = function (name) {
    var arr;
    var reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
    if (arr = document.cookie.match(reg))
        return JSON.parse(unescape(arr[2]));
    else
        return null
};


