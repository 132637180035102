import http from '../../cloud/http'

//家具管理
export const furniture = (action, data, method) => {
    data = data || {};
    method = method || 'get';
    if (method == 'get') {
        return http({
            url: '/admin/journal/furniture/index/' + action + '/',
            method: 'get',
            params: data
        })
    } else {
        return http({
            url: '/admin/journal/furniture/index/' + action + '/',
            method: 'post',
            data
        })
    }
};


//分类管理
export const all_sort = (action, data, method) => {
    data = data || {};
    method = method || 'get';
    if (method == 'get') {
        return http({
            url: '/admin/journal/all_sort/index/' + action + '/',
            method: 'get',
            params: data
        })
    } else {
        return http({
            url: '/admin/journal/all_sort/index/' + action + '/',
            method: 'post',
            data
        })
    }
};


//文案管理
export const pet_text = (action, data, method) => {
    data = data || {};
    method = method || 'get';
    if (method == 'get') {
        return http({
            url: '/admin/journal/pet_text/index/' + action + '/',
            method: 'get',
            params: data
        })
    } else {
        return http({
            url: '/admin/journal/pet_text/index/' + action + '/',
            method: 'post',
            data
        })
    }
};


//元素管理
export const element = (action, data, method) => {
    data = data || {};
    method = method || 'get';
    if (method == 'get') {
        return http({
            url: '/admin/journal/element/index/' + action + '/',
            method: 'get',
            params: data
        })
    } else {
        return http({
            url: '/admin/journal/element/index/' + action + '/',
            method: 'post',
            data
        })
    }
};


//封面管理
export const cover = (action, data, method) => {
    data = data || {};
    method = method || 'get';
    if (method == 'get') {
        return http({
            url: '/admin/journal/cover/index/' + action + '/',
            method: 'get',
            params: data
        })
    } else {
        return http({
            url: '/admin/journal/cover/index/' + action + '/',
            method: 'post',
            data
        })
    }
};

//手账模板
export const text_tpl = (action, data, method) => {
    data = data || {};
    method = method || 'get';
    if (method == 'get') {
        return http({
            url: '/admin/journal/text_tpl/index/' + action + '/',
            method: 'get',
            params: data
        })
    } else {
        return http({
            url: '/admin/journal/text_tpl/index/' + action + '/',
            method: 'post',
            data
        })
    }
};

//用户手账管理
export const user_text = (action, data, method) => {
    data = data || {};
    method = method || 'get';
    if (method == 'get') {
        return http({
            url: '/admin/journal/user_text/index/' + action + '/',
            method: 'get',
            params: data
        })
    } else {
        return http({
            url: '/admin/journal/user_text/index/' + action + '/',
            method: 'post',
            data
        })
    }
};

//广告渠道展示管理
export const advert = (action, data, method) => {
    data = data || {};
    method = method || 'get';
    if (method == 'get') {
        return http({
            url: '/admin/journal/advert/index/' + action + '/',
            method: 'get',
            params: data
        })
    } else {
        return http({
            url: '/admin/journal/advert/index/' + action + '/',
            method: 'post',
            data
        })
    }
};
//广告平台配置管理
export const advert_config = (action, data, method) => {
    data = data || {};
    method = method || 'get';
    if (method == 'get') {
        return http({
            url: '/admin/journal/advert_config/index/' + action + '/',
            method: 'get',
            params: data
        })
    } else {
        return http({
            url: '/admin/journal/advert_config/index/' + action + '/',
            method: 'post',
            data
        })
    }
};
//用户管理
export const user = (action, data, method) => {
    data = data || {};
    method = method || 'get';
    if (method == 'get') {
        return http({
            url: '/admin/journal/user/index/' + action + '/',
            method: 'get',
            params: data
        })
    } else {
        return http({
            url: '/admin/journal/user/index/' + action + '/',
            method: 'post',
            data
        })
    }
};
//用户手账评论
export const user_text_comment = (action, data, method) => {
    data = data || {};
    method = method || 'get';
    if (method == 'get') {
        return http({
            url: '/admin/journal/user_text_comment/index/' + action + '/',
            method: 'get',
            params: data
        })
    } else {
        return http({
            url: '/admin/journal/user_text_comment/index/' + action + '/',
            method: 'post',
            data
        })
    }
};

//用户消息管理
export const message = (action, data, method) => {
    data = data || {};
    method = method || 'get';
    if (method == 'get') {
        return http({
            url: '/admin/journal/message/index/' + action + '/',
            method: 'get',
            params: data
        })
    } else {
        return http({
            url: '/admin/journal/message/index/' + action + '/',
            method: 'post',
            data
        })
    }
};

//用户消息管理
export const vip_config = (action, data, method) => {
    data = data || {};
    method = method || 'get';
    if (method == 'get') {
        return http({
            url: '/admin/journal/vip_config/index/' + action + '/',
            method: 'get',
            params: data
        })
    } else {
        return http({
            url: '/admin/journal/vip_config/index/' + action + '/',
            method: 'post',
            data
        })
    }
};

//用户VIP购买记录
export const vip_purchase_record = (action, data, method) => {
    data = data || {};
    method = method || 'get';
    if (method == 'get') {
        return http({
            url: '/admin/journal/user_vip_purchase_record/index/' + action + '/',
            method: 'get',
            params: data
        })
    } else {
        return http({
            url: '/admin/journal/user_vip_purchase_record/index/' + action + '/',
            method: 'post',
            data
        })
    }
};


//手账转盘抽奖
export const turntable = (action, data, method) => {
    data = data || {};
    method = method || 'get';
    if (method == 'get') {
        return http({
            url: '/admin/journal/turntable/index/' + action + '/',
            method: 'get',
            params: data
        })
    } else {
        return http({
            url: '/admin/journal/turntable/index/' + action + '/',
            method: 'post',
            data
        })
    }
};
//用户封禁记录
export const user_disable_record = (action, data, method) => {
    data = data || {};
    method = method || 'get';
    if (method == 'get') {
        return http({
            url: '/admin/journal/user_disable_record/index/' + action + '/',
            method: 'get',
            params: data
        })
    } else {
        return http({
            url: '/admin/journal/user_disable_record/index/' + action + '/',
            method: 'post',
            data
        })
    }
};
//用户举报记录
export const user_report_record = (action, data, method) => {
    data = data || {};
    method = method || 'get';
    if (method == 'get') {
        return http({
            url: '/admin/journal/user_report_record/index/' + action + '/',
            method: 'get',
            params: data
        })
    } else {
        return http({
            url: '/admin/journal/user_report_record/index/' + action + '/',
            method: 'post',
            data
        })
    }
};

//手账APP版本管理
export const version = (action, data, method) => {
    data = data || {};
    method = method || 'get';
    if (method == 'get') {
        return http({
            url: '/admin/journal/version/index/' + action + '/',
            method: 'get',
            params: data
        })
    } else {
        return http({
            url: '/admin/journal/version/index/' + action + '/',
            method: 'post',
            data
        })
    }
};


//签到基础信息配置接口  
export const signInfo = (action, data, method) => {
    data = data || {};
    method = method || 'get';
    if (method == 'get') {
        return http({
            url: '/admin/journal/sign_info/index/' + action + '/',
            method: 'get',
            params: data
        })
    } else {
        return http({
            url: '/admin/journal/sign_info/index/' + action + '/',
            method: 'post',
            data
        })
    }
};

//签到基础信息配置接口  
export const AddSignInfo = (action, data, method) => {
    data = data || {};
    method = method || 'get';
    if (method == 'get') {
        return http({
            url: '/admin/journal/sign_info/index/' + action + '/',
            method: 'get',
            params: data
        })
    } else {
        return http({
            url: '/admin/journal/sign_info/index/' + action + '/',
            method: 'post',
            data
        })
    }
};


//签到删除 
export const DeleteSignInfo = (action, data, method) => {
    data = data || {};
    method = method || 'get';
    if (method == 'get') {
        return http({
            url: '/admin/journal/sign_info/index/' + action + '/',
            method: 'get',
            params: data
        })
    } else {
        return http({
            url: '/admin/journal/sign_info/index/' + action + '/',
            method: 'post',
            data
        })
    }
};


// 签到家具 和 签到贴纸   套装
 export const GetFurnitureSuit = (action, data, method) => {
    data = data || {};
    method = method || 'get';
    if (method == 'get') {
        return http({
            url: '/admin/journal/all_sort/index/' + action + '/',
            method: 'get',
            params: data
        })
    } else {
        return http({
            url: '/admin/journal/all_sort/index/' + action + '/',
            method: 'post',
            data
        })
    }
};

//签到家具2
export const GetFurnitureParts = (action, data, method) => {
    data = data || {};
    method = method || 'get';
    if (method == 'get') {
        return http({
            url: '/admin/journal/furniture/index/' + action + '/',
            method: 'get',
            params: data
        })
    } else {
        return http({
            url: '/admin/journal/furniture/index/' + action + '/',
            method: 'post',
            data
        })
    }
};

//保存一天的配置
export const Save = (action, data, method) => {
    data = data || {};
    method = method || 'get';
    if (method == 'get') {
        return http({
            url: '/admin/journal/sign_reward/' + action + '/',
            method: 'get',
            params: data
        })
    } else {
        return http({
            url: '/admin/journal/sign_reward/' + action + '/',
            method: 'post',
            data
        })
    }
};




//任务列表
export const taskList = (action, data, method) => {
    data = data || {};
    method = method || 'get';
    if (method == 'get') {
        return http({
            url: '/admin/journal/user_task/index/' + action + '/',
            method: 'get',
            params: data
        })
    } else {
        return http({
            url: '/admin/journal/user_task/index/' + action + '/',
            method: 'post',
            data
        })
    }
};


//任务类型
export const taskType = (action, data, method) => {
    data = data || {};
    method = method || 'get';
    if (method == 'get') {
        return http({
            url: '/admin/journal/user_task/index/' + action + '/',
            method: 'get',
            params: data
        })
    } else {
        return http({
            url: '/admin/journal/user_task/index/' + action + '/',
            method: 'post',
            data
        })
    }
};


//任务方式
export const TaskWay = (action, data, method) => {
    data = data || {};
    method = method || 'get';
    if (method == 'get') {
        return http({
            url: '/admin/journal/user_task/index/' + action + '/',
            method: 'get',
            params: data
        })
    } else {
        return http({
            url: '/admin/journal/user_task/index/' + action + '/',
            method: 'post',
            data
        })
    }
};

//任务创建或者修改
export const TaskSave = (action, data, method) => {
    data = data || {};
    method = method || 'get';
    if (method == 'get') {
        return http({
            url: '/admin/journal/user_task/index/' + action + '/',
            method: 'get',
            params: data
        })
    } else {
        return http({
            url: '/admin/journal/user_task/index/' + action + '/',
            method: 'post',
            data
        })
    }
};
//任务删除
export const TaskDel = (action, data, method) => {
    data = data || {};
    method = method || 'get';
    if (method == 'get') {
        return http({
            url: '/admin/journal/user_task/index/' + action + '/',
            method: 'get',
            params: data
        })
    } else {
        return http({
            url: '/admin/journal/user_task/index/' + action + '/',
            method: 'post',
            data
        })
    }
};

//任务奖励（GetTaskReward查询，Save保存编辑，DeleteUserTaskReward删除）
export const RewardSave = (action, data, method) => {
    data = data || {};
    method = method || 'get';
    if (method == 'get') {
        return http({
            url: '/admin/journal/user_task_reward/' + action + '/',
            method: 'get',
            params: data
        })
    } else { 
        return http({
            url: '/admin/journal/user_task_reward/' + action + '/',  
            method: 'post',
            data
        })
    }
};

//垃圾（List列表 Save修改或保存垃圾 DeleteRubbish删除垃圾）
export const rubbishList = (action, data, method) => {
    data = data || {};
    method = method || 'get';
    if (method == 'get') {
        return http({
            url: '/admin/journal/rubbish/index/' + action + '/',
            method: 'get',
            params: data
        })
    } else { 
        return http({
            url: '/admin/journal/rubbish/index/' + action + '/',  
            method: 'post',
            data
        })
    }
};


//垃圾奖励（GetRubbishReward查询垃圾的奖励 Save修改或保存垃圾的奖励 DeleteRubbishReward删除垃圾的奖励）
export const rubbishReward = (action, data, method) => {
    data = data || {};
    method = method || 'get';
    if (method == 'get') {
        return http({
            url: '/admin/journal/rubbish_reward/' + action + '/',
            method: 'get',
            params: data
        })
    } else { 
        return http({
            url: '/admin/journal/rubbish_reward/' + action + '/',  
            method: 'post',
            data
        })
    }
};


//盲盒奖品列表 list 列表 save保存
export const blindBoxList = (action, data, method) => {
    data = data || {};
    method = method || 'get';
    if (method == 'get') {
        return http({
            url: '/admin/journal/blind_box_luck_draw/index/' + action + '/',
            method: 'get',
            params: data
        })
    } else { 
        return http({
            url: '/admin/journal/blind_box_luck_draw/index/' + action + '/',  
            method: 'post',
            data
        })
    }
};

//活动管理
export const activity = (action, data, method) => {
    data = data || {};
    method = method || 'get';
    if (method == 'get') {
        return http({
            url: '/admin/journal/activity/' + action + '/',
            method: 'get',
            params: data
        })
    } else {
        return http({
            url: '/admin/journal/activity/' + action + '/',
            method: 'post',
            data
        })
    }
};
//活动礼包管理
export const activity_gift_bag = (action, data, method) => {
    data = data || {};
    method = method || 'get';
    if (method == 'get') {
        return http({
            url: '/admin/journal/activity_gift_bag/' + action + '/',
            method: 'get',
            params: data
        })
    } else {
        return http({
            url: '/admin/journal/activity_gift_bag/' + action + '/',
            method: 'post',
            data
        })
    }
};


//活动礼包奖励内容管理
export const activity_gift_bag_reward = (action, data, method) => {
    data = data || {};
    method = method || 'get';
    if (method == 'get') {
        return http({
            url: '/admin/journal/activity_gift_bag_reward/' + action + '/',
            method: 'get',
            params: data
        })
    } else {
        return http({
            url: '/admin/journal/activity_gift_bag_reward/' + action + '/',
            method: 'post',
            data
        })
    }
};
//活动礼包奖励购买记录
export const activity_gift_bag_purchase_record = (action, data, method) => {
    data = data || {};
    method = method || 'get';
    if (method == 'get') {
        return http({
            url: '/admin/journal/activity_gift_bag_purchase_record/' + action + '/',
            method: 'get',
            params: data
        })
    } else {
        return http({
            url: '/admin/journal/activity_gift_bag_purchase_record/' + action + '/',
            method: 'post',
            data
        })
    } 
};

//盲盒订单
export const blindBoxOrder = (action, data, method) => {
    data = data || {};
    method = method || 'get';
    if (method == 'get') {
        return http({
            url: '/admin/journal/blind_box_luck_draw_num_purchase_record/index/' + action + '/',
            method: 'get',
            params: data
        })
    } else {
        return http({
            url: '/admin/journal/blind_box_luck_draw_num_purchase_record/index/' + action + '/',
            method: 'post',
            data
        })
    } 
};

//用户中奖
export const blindBoxOrderinfo = (action, data, method) => {
    data = data || {};
    method = method || 'get';
    if (method == 'get') {
        return http({
            url: '/admin/journal/blind_box_luck_draw_record/index/' + action + '/',
            method: 'get',
            params: data
        })
    } else {
        return http({
            url: '/admin/journal/blind_box_luck_draw_record/index/' + action + '/',
            method: 'post',
            data
        })
    } 
};



//广告弹窗列表
export const adbertList = (action, data, method) => {
    data = data || {}
    method = method || 'get'
    if (method == 'get') {
        return http({
            url: '/admin/journal/advert_popup/' + action + '/',
            method: 'get',
            params: data
        })
    } else {
        return http({
            url: '/admin/journal/advert_popup/' + action + '/',
            method: 'post',
            data
        })
    }
}
