import http from '../../cloud/http'

export const bannerList = (action, data, method) => {
    data = data || {};
    method = method || 'get';
    if (method == 'get') {
        return http({
            url: '/admin/official/banner/index/' + action + '/',
            method: 'get',
            params: data
        })
    } else {
        return http({
            url: '/admin/official/banner/index/' + action + '/',
            method: 'post',
            data
        })
    }
};


//保存（编辑）banner图
export const bannerSave = (data) => {
        return http({
            url: '/admin/official/banner/index/Save',
            method: 'post',
            data
        })

}


//删除banner图
export const bannerDle = (action, data, method) => {
    data = data || {}
    method = method || 'get'
    if (method == 'get') {
        return http({
            url: '/admin/official/banner/index/Delete',
            method: 'get',
            params: data
        })
    } else {
        return http({
            url: '/admin/official/banner/index/Delete',
            method: 'post',
            data
        })
    }
}

//项目详情列表
export const oneInfo = (action, data, method) => {
    data = data || {}
    method = method || 'get'
    if (method == 'get') {
        return http({
            url: '/admin/official/info/index/' + action + '/',
            method: 'get',
            params: data
        })
    } else {
        return http({
            url: '/admin/official/info/index/' + action + '/',
            method: 'post',
            data
        })
    }
}


//保存（编辑）项目信息
export const oneSave = (data) => {
        return http({
            url: '/admin/official/info/index/Save',
            method: 'post',
            data
        })
    
}

//删除项目信息
export const oneDle = (action, data, method) => {
    data = data || {}
    method = method || 'get'
    if (method == 'get') {
        return http({
            url: '/admin/official/info/index/' + action + '/',
            method: 'get',
            params: data
        })
    } else {
        return http({
            url: '/admin/official/info/index/' + action + '/',
            method: 'post',
            data
        })
    }
}

//图片上传
export const imgUp = (action, data, method) => {
    data = data || {}
    method = method || 'get'
    if (method == 'get') {
        return http({
            url: '/common/upload/multipart/',
            method: 'get',
            params: data
        })
    } else {
        return http({
            url: '/common/upload/multipart/',
            method: 'post',
            data
        })
    }
}

