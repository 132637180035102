import UserService from '../../services/User.js'
import router from '../../router'

export default {
    install: Vue => {
        Vue.mixin({
            methods: {
                _user() {

                }
            }
        });
        Vue.prototype.$eventHub = new Vue();
        Vue.prototype.$user = {
            getName() {
                return UserService.getName()
            },
            reset(cb) {
                UserService.referer = '/home';
                UserService.reset();
                cb()
            },
            getReferer() {
                let referer = UserService.referer;
                if (referer === '/login') {
                    return '/home'
                }
                return UserService.referer
            },
            checkLogin(cb) {
                if (UserService.info.u_id) {
                    cb(UserService.info)
                } else {
                    Vue.prototype.$eventHub.$on('user_login_success', () => {
                        cb(UserService.info)
                    })
                }
            },
            init() {
                Vue.prototype.$loading.show();
                //let referer = window.location.hash.replace("#", "")
                let referer = window.location.pathname.replace("/cp", "") + window.location.search || '/home'
                Vue.prototype.$loading.show();
                UserService.init(isLogin => {
                    Vue.prototype.$loading.close();
                    if (!isLogin && referer != '/login') {
                        UserService.referer = referer;
                        router.push('/login');
                    } else {
                        Vue.prototype.$eventHub.$emit("user_login_success");
                    }
                }, error => {
                    Vue.prototype.$loading.close();
                    Vue.prototype.$message.error('检查用户登录失败:' + error);
                    if (referer != '/login') {
                        UserService.referer = referer;
                        router.push('/login')
                    }
                });
            },
            logout(cb) {
                UserService.logout(cb)
            }
        }
    }
}
