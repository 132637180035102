import http from '../../cloud/http'

//栏目管理
export const catalog = (action, data, method) => {
    data = data || {}
    method = method || 'get'
    if (method == 'get') {
        return http({
            url: '/admin/article/catalog/' + action + '/',
            method: 'get',
            params: data
        })
    } else {
        return http({
            url: '/admin/article/catalog/' + action + '/',
            method: 'post',
            data
        })
    }
}
//文章管理
export const article = (action, data, method) => {
    data = data || {}
    method = method || 'get'
    if (method == 'get') {
        return http({
            url: '/admin/article/article/' + action + '/',
            method: 'get',
            params: data
        })
    } else {
        return http({
            url: '/admin/article/article/' + action + '/',
            method: 'post',
            data
        })
    }
}